<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-book" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      SHS Details
                    </div>
                    <div class="text-sm text-500">
                      Details of programme record
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col comp-grid">
            <div class="">
              <div>
                <div class="relative-position" style="min-height: 100px">
                  <template v-if="!loading && item">
                    <div class="row q-col-gutter-x-md">
                      <div class="col-12">
                        <div class="grid align-items-center">
                          <div class="col">
                            <div class="text-500 text-sm mb-1">
                              SHS Name:
                            </div>
                            <div class="font-bold">
                              {{ item.shs_name }} 
                            </div>
                          </div>
                        </div>
                        <hr />
                           <div class="grid align-items-center">
                          <div class="col">
                            <div class="text-500 text-sm mb-1">
                               Email:
                            </div>
                            <div class="font-bold">
                              {{ item.email }} 
                            </div>
                          </div>
                        </div>
                        <hr />
                           <div class="grid align-items-center">
                          <div class="col">
                            <div class="text-500 text-sm mb-1">
                              Region:
                            </div>
                            <div class="font-bold">
                              {{ item.region }} 
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            <div class="text-500 text-sm mb-1">
                              District:
                            </div>
                            <div class="font-bold">
                              {{ item.district }} 
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            <div class="text-500 text-sm mb-1">
                              Location:
                            </div>
                            <div class="font-bold">
                              {{ item.location }} 
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="grid align-items-center">
                          <div class="col">
                            <div class="text-500 text-sm mb-1">
                              Gender:
                            </div>
                            <div class="font-bold">
                              {{ item.gender }} 
                            </div>
                          </div>
                        </div>
                        <hr />
                         <div class="grid align-items-center">
                          <div class="col">
                            <div class="text-500 text-sm mb-1">
                              Residency:
                            </div>
                            <div class="font-bold">
                              {{ item.residency }} 
                            </div>
                          </div>
                        </div>
                        <hr />
                        
                        <hr />
                        <div class="flex justify-content-between">
                          <Menubar
                            class="p-0"
                            ref="actionMenu"
                            :model="getActionMenuModel(item)"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="loading">
                    <div class="p-3 text-center">
                      <ProgressSpinner style="width: 50px; height: 50px" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { PageMixin } from "../../mixins/page.js";
import { ViewPageMixin } from "../../mixins/viewpage.js";
export default {
  name: "viewshslistPage",
  components: {},
  mixins: [PageMixin, ViewPageMixin],
  props: {
    pageName: {
      type: String,
      default: "shs_list",
    },
    idName: {
      type: String,
      default: "shs_id",
    },
    routeName: {
      type: String,
      default: "shs_listview",
    },
    pagePath: {
      type: String,
      default: "shs_list/view",
    },
    apiPath: {
      type: String,
      default: "shs_list/view",
    },
  },
  data() {
    return {
      item: {
        default: {},
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "SHS Details";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["shs_list/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("shs_list/setCurrentRecord", value);
      },
    },
  },
  meta() {
    return {
      title: this.pageTitle,
    };
  },
  methods: {
    ...mapActions("shs_list", ["fetchRecord", "deleteRecord"]),
    getActionMenuModel(data) {
      return [
        {
          label: "Edit",
          command: (event) => {
            this.openPageDialog(
              {
                page: "shs_list/edit",
                url: `/shs_list/edit/${data.shs_id}`,
              },
              { persistent: true, closeBtn: true }
            );
          },
          icon: "pi pi-pencil",
        },
        {
          label: "Delete",
          command: (event) => {
            this.deleteItem(data.shs_id);
          },
          icon: "pi pi-minus-circle",
        },
      ];
    },
  },
  watch: {
    $route(to, from) {
      //only fetch data when navigated to this page
      if (to.name == this.routeName) {
        //this.load();
      }
    },
  },
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
